@font-face {
    font-family: 'Helvetica';
    font-weight: normal;
    src: url('./fonts/Helvetica/Helvetica-Regular.woff');
}
@font-face {
    font-family: 'Helvetica';
    font-weight: 300;
    src: url('./fonts/Helvetica/Helvetica-Thin.woff');
}
@font-face {
    font-family: 'Helvetica';
    font-weight: 500;
    src: url('./fonts/Helvetica/Helvetica-Medium.woff');
}
@font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/Helvetica/Helvetica-Medium.woff');
}

body {
  margin: 0;
  font-family: 'Helvetica';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
